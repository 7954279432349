import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createTransaction } from "../../api/identity";
import { store, saveValue } from "../../redux/store";

export default function IdentityComponent(props) {
  const attributes = useSelector((state) => state.form);

  useEffect(() => {
    (async function () {
      const res = await createTransaction(
        attributes.Applicant._id,
        attributes.Application._id
      );

      console.log(res);
      window.location = res.data.url;
    })();
  });

  return <></>;
}
