import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import { store, saveValue } from "../../redux/store";
import { createCode } from "../../api/phoneNumber";

export default function RadioButtonGroup(props) {
  const history = useHistory();
  const applicant = useSelector((state) => state.form.Applicant);
  const value = useSelector((state) => state.form[props.object][props.name]);
  console.log(value);

  const handleSendMobileCode = async () => {
    //TODO: validation for mobile
    store.dispatch(
      saveValue({
        [props.object]: {
          status: "not verified",
          phoneNumberType: "mobile",
        },
      })
    );

    const response = await createCode({ applicant, number: value });
    console.log(response.status);

    if (response.status === 200) {
      if (props.activeStep === props.steps.length - 1)
        return history.push(`/${props.route + 1}`);
      props.handleNext();
    }
  };

  const handleValue = (e) => {
    console.log(e.target.value);
    store.dispatch(
      saveValue({
        [props.object]: {
          [props.name]: e.target.value,
        },
      })
    );
  };

  const startAdornment = props.startAdornment && (
    <InputAdornment position="start">{props.startAdornment}</InputAdornment>
  );

  const endAdornment = props.endAdornment && (
    <InputAdornment position="end">{props.endAdornment}</InputAdornment>
  );

  return (
    <>
      <TextField
        name={props.name}
        {...props}
        value={value}
        // defaultValue={state.form[props.object][props.defaultValue]}
        onChange={handleValue}
        InputProps={{
          startAdornment,
          endAdornment,
        }}
        style={{ margin: " 0 0 30px 0" }}
      />
      {props.steps && (
        <div style={{ margin: "30px 0" }}>
          <div>
            <Button
              disabled={props.activeStep === 0}
              onClick={props.handleBack}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={async () => {
                // await handleSendMobileCode();
                props.handleNext();
              }}
            >
              {props.activeStep === props.steps.length - 1 ? "Finish" : "Next"}
              {/* {value ? "Next" : props.buttonLabel || "Next"} */}
            </Button>
          </div>
        </div>
      )}
    </>
  );
}
