import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepButton from "@material-ui/core/StepButton";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import RadioButtonGroup from "../elements/RadioButtonGroup";
import TextFieldGroup from "../elements/TextFieldGroup";
import SliderGroup from "../elements/SliderGroup";
import MobileFieldGroup from "../elements/MobileFieldGroup";
import CodeFieldGroup from "../elements/CodeFieldGroup";
import EmailFieldGroup from "../elements/EmailFieldGroup";
import BusinessSearchGroup from "../elements/BusinessSearchGroup";
import { useSelector } from "react-redux";
// import socket from "../../api/socket";

export default function StepperComponent(props) {
  const history = useHistory();
  const attributes = useSelector((state) => state.form);

  console.log({ attributes })


  const elements = {
    TextField,
    Slider,
    RadioButtonGroup,
    TextFieldGroup,
    SliderGroup,
    MobileFieldGroup,
    CodeFieldGroup,
    EmailFieldGroup,
    BusinessSearchGroup,
  };
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const steps = props.components.map((item) => item.label);

  const stepsLength = props.components.filter((item, i) => (props.components[i].conditional &&
    (attributes?.[props.components[i].object]?.[props.components[i].conditional] === props.components[i].condition)) ||
    (props.components[i].name && !props.components[i].conditional)).map((item) => item.label);

 

  const handleNext = (type) => {
    console.log(
      isLastStep(),
      !allStepsCompleted(),
      activeStep,
      totalSteps() - 1,
      completed
    );
    // const newActiveStep =
    //   isLastStep() && !allStepsCompleted()
    //     ? // It's the last step, but not all steps have been completed,
    //       // find the first step that has been completed
    //       steps.findIndex((step, i) => !(i in completed))
    //     : activeStep + 1;
    if (type === "button") return setTimeout(() => setActiveStep(activeStep + 1), 600)

    return setActiveStep(activeStep + 1)
  };

  const totalSteps = () => {
    return stepsLength.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleBack = () =>
    setActiveStep((prevActiveStep) => prevActiveStep - 1);

  const handleReset = () => setActiveStep(0);

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  // socket.send("Hello!");

  // or with emit() and custom event names
  // socket.emit(
  //   "greetings",
  //   "Hello!",
  //   { mr: "john" },
  //   Uint8Array.from([1, 2, 3, 4])
  // );

  // handle the event sent with socket.send()
  // socket.on("message", (data) => {
  //   console.log(data);
  // });

  // handle the event sent with socket.emit()
  // socket.on("greetings", (elem1, elem2, elem3) => {
  //   console.log(elem1, elem2, elem3);
  // });

  console.log(stepsLength.length, activeStep + 1)

  return (
    <Grid container style={{ padding: "30px 30px 0", }}>
      <Grid item xs={12}>
        <Typography variant="h5" style={{ margin: "0 0 30px 0" }}>
          {props.label}
        </Typography>
        <Stepper
          nonLinear
          style={{ padding: 0, }}
          activeStep={activeStep}
          orientation="vertical"
        >
          {steps.map((label, i) => {
            const Component = elements[props.components[i].componentType];
            return ((props.components[i].conditional &&
              (attributes?.[props.components[i].object]?.[props.components[i].conditional] === props.components[i].condition)) ||
              (props.components[i].name && !props.components[i].conditional)) &&
              <Step key={label}>
                {/* <StepLabel>{label}</StepLabel> */}
                <StepButton onClick={handleStep(i)} completed={completed[i]}>
                  {label}
                </StepButton>
                <StepContent>
                  <div style={{ margin: "0 0 20px" }}>
                    <Typography style={{ fontSize: "20px" }}>{props.components[i].heading}</Typography>
                    <Typography>{props.components[i].description}</Typography>
                  </div>

                  <Component
                    {...props.components[i]}
                    section={props.name}
                    steps={stepsLength}
                    handleNext={handleNext}
                    handleBack={handleBack}
                    handleReset={handleReset}
                    activeStep={activeStep}
                    route={props.route}
                  />


                  {
                    props.components[i]?.secondary &&
                    // attributes[props.components[i]?.secondary.condition.object]
                    // [props.components[i]?.secondary?.condition.attribute]
                    // [props.components[i]?.secondary?.condition.value] ===
                    // props.components[i]?.secondary?.condition.value &&
                    < Component
                      {...props.components[i]?.secondary}
                      section={props.name}
                      steps={stepsLength}
                      handleNext={handleNext}
                      handleBack={handleBack}
                      handleReset={handleReset}
                      activeStep={activeStep}
                      route={props.route}
                    />
                  }
                </StepContent>
              </Step>
          })}
        </Stepper>
        {
          stepsLength.length === activeStep  &&
          <Paper square elevation={0} style={{ marginTop: "30px" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => history.push(`/${props.route + 1}`)}
            >
              Get your quote
            </Button>
          </Paper>
        }
      </Grid>
    </Grid>
  );
}
