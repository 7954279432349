import axios from 'axios'
import {getAPIURL} from '../utils'

export async function signPrivacy(data) {
    console.log(data)
    try {
        return await axios({
            method: 'post',
            url: `${getAPIURL() || 'http://localhost:8000'}/document/privacy`,
            data
        });
    } catch (error) {
        console.error(error);
        return error
    }
}