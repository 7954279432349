import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { store, saveValue } from "../redux/store";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { startApplication } from "../api/application";
import { getIntroducer } from "../api/introducer";

export default function BaseComponent() {
  const history = useHistory();
  const handleValue = (model, key, value) => {
    store.dispatch(saveValue({ [model]: { [key]: value } }));
  };

  const [introdcerLogo, setIntroducerLogo] = useState(null);
  const [supplierABN, setIntroducerABN] = useState(null);

  useEffect(() => {
    (async function () {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      console.log(getPathName());
      if (getPathName()) {
        handleValue("Introducer", "shortCode", getPathName());
        // getSupplier(getPathName());
      }
      const { token } = localStorage;
      const res = await startApplication({ token, shortCode: getPathName() });
      console.log(res);
      if (res?.data) {
        const { applicant, applicantToken } = res.data;
        console.log(applicant, applicantToken);

        handleValue("Application", "_id", applicant.application);
        handleValue("Customer", "_id", applicant.customer);
        handleValue("Applicant", "_id", applicant._id);
        localStorage.token = applicantToken;
        history.push(`/0`);
      }
    })();
  });

  async function getSupplier(shortCode) {
    const supplierData = await getIntroducer(shortCode)
    setIntroducerLogo(supplierData?.data.logo)
    setIntroducerABN(supplierData?.data.abn)
  }

  const getPathName = () => {
    return window.location.pathname.substring(4)
  }


  return <></>;
}
