import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { store, saveValue } from "../../redux/store";
import Typography from "@material-ui/core/Typography";

export default function TypographyFieldGroup(props) {
  const history = useHistory();
  const value = useSelector((state) => state.form[props.object][props.name]);

  return (
    <>
      <Typography
        name={props.name}
        {...props}
        style={{ margin: " 0 0 5px 0", textAlign: "center" }}
      >{props?.startAdornment}{props.value}<span style={{ fontSize: "20px" }}>{props?.endAdornment}</span></Typography>
    </>
  );
}
