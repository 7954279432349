import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Container from "@material-ui/core/Container";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { nanoid } from "nanoid";
import { Provider } from "react-redux";
import { store } from "./redux/store";
// import form from "./form.json";
import form from "./azora-commercial-form.json";
import Page from "./components/Page";
import Introducer from "./components/Introducer";
import StartApplication from "./components/StartApplication";
import Privacy from "./components/Privacy";
import IdentitySuccess from "./components/IdentitySuccess";
import colours from "./style/color";
import PrivacySuccess from "./components/PrivacySuccess"

// Socket()
// import { io } from "socket.io-client";
// const socket = io("http://localhost:8000", {
//   reconnectionDelay: 1000,
//   reconnection: true,
//   reconnectionAttempts: 10,
//   transports: ["websocket"],
//   agent: false, // [2] Please don't set this to true
//   upgrade: false,
//   rejectUnauthorized: false,
// });

// socket.on("connect", () => {
//   console.log(socket.connected);
//   // either with send()
//   socket.send("Hello!");

//   // or with emit() and custom event names
//   socket.emit(
//     "greetings",
//     "Hello!",
//     { mr: "john" },
//     Uint8Array.from([1, 2, 3, 4])
//   );
// });

// socket.on("connect_error", (err) => {
//   console.log(`connect_error due to ${err.message}`);
// });

// // handle the event sent with socket.send()
// socket.on("message", (data) => {
//   console.log(data);
// });

// // handle the event sent with socket.emit()
// socket.on("greetings", (elem1, elem2, elem3) => {
//   console.log(elem1, elem2, elem3);
// });

let theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: colours.black,
    },
    secondary: {
      main: colours.yellow,
    },
    background: {
      default: colours.white,
      paper: colours.white,
    },
  },

  typography: {
    body1: {
      letterSpacing: "0.5px",
    },
    fontFamily: [
      "Cabin",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  overrides: {
    MuiToggleButton: {
      selected: {
        background: "#000000",
      },
    },
  },
});

theme = responsiveFontSizes(theme);

function App(props) {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Container maxWidth="sm" style={{ padding: 0 }}>
            <Switch>
              {form.map((section, i) => (
                <Route
                  key={nanoid()}
                  path={`/${i}`}
                  exact
                  component={() => (
                    <Page
                      key={nanoid()}
                      {...props}
                      section={section}
                      route={i}
                    />
                  )}
                />
              ))}
              {/* <Route
                key={nanoid()}
                path={`/`}
                exact
                component={() => <Base key={nanoid()} />}
              ></Route> */}
              <Route
                key={nanoid()}
                path={`/in/:shortcode`}
                component={() => <StartApplication key={nanoid()} />}
              ></Route>
              <Route
                key={nanoid()}
                path={`/introducer`}
                exact
                component={() => <Introducer key={nanoid()} />}
              ></Route>
              <Route
                key={nanoid()}
                path={`/privacy`}
                exact
                component={() => <Privacy key={nanoid()} />}
              ></Route>
              <Route
                key={nanoid()}
                path={`/identity-success`}
                exact
                component={() => <IdentitySuccess key={nanoid()} />}
              ></Route>
              <Route
                key={nanoid()}
                path={`/privacy-success`}
                exact
                component={() => <PrivacySuccess key={nanoid()} />}
              ></Route>
            </Switch>
          </Container>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
