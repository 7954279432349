import { createSlice, configureStore, current } from "@reduxjs/toolkit";
import { getInitialState } from "../utils";
import { SAVE_VALUE } from "./constants";
import form from "../azora-commercial-form.json";

let initialState = getInitialState(form);

if (localStorage.form) initialState = JSON.parse(localStorage.form);

const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    saveValue: (state, action) => {
      const attr = Object.keys(action.payload)[0];
      // console.log(action.payload);
      state[attr] = Object.assign({}, state[attr], action.payload[attr]);
    },
    resetState: () => initialState,
  },
});

export const { saveValue, resetState } = formSlice.actions;

export const store = configureStore({
  reducer: {
    form: formSlice.reducer,
  },
});

store.subscribe(() => {
  localStorage.setItem("form", JSON.stringify(store.getState().form));
  console.log(store.getState().form);
});
