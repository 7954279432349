import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { store, saveValue } from "../../redux/store";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { createCode, verifyCode } from "../../api/phoneNumber";

export default function RadioButtonGroup(props) {
  const history = useHistory();
  const [code, setCode] = useState("");
  const [wrongCode, setWrongCode] = useState(false);
  const [expiredCode, setExpiredCode] = useState(false);
  const applicant = useSelector((state) => state.form.Applicant);
  const value = useSelector((state) => state.form.PhoneNumber.number);
  console.log(props);

  const handleVerifyCode = async (n) => {
    const response = await verifyCode({ applicantId: applicant._id, code: n });
    console.log(response.status);

    if (response.status === 200) {
      store.dispatch(
        saveValue({
          PhoneNumber: {
            status: "verfied",
          },
        })
      );
      if (props.activeStep === props.steps.length - 1)
        return history.push(`/${props.route + 1}`);
      props.handleNext();
    }

    if (response.status === 406) {
      setWrongCode(true);
    }
  };

  const handleValue = async (e) => {
    console.log(e.target.value, e.target.value.length);
    setCode(e.target.value);
    if (e.target.value.length === 4) {
      await handleVerifyCode(e.target.value);
    }
  };

  const startAdornment = props.startAdornment && (
    <InputAdornment position="start">{props.startAdornment}</InputAdornment>
  );

  const endAdornment = props.endAdornment && (
    <InputAdornment position="end">{props.endAdornment}</InputAdornment>
  );

  return (
    <>
      <TextField
        name={props.name}
        {...props}
        value={code}
        onChange={handleValue}
        InputProps={{
          startAdornment,
          endAdornment,
        }}
        style={{ margin: " 0 0 30px 0" }}
      />
      {props.steps && (
        <div style={{ margin: "30px 0" }}>
          <div>
            <Button
              disabled={props.activeStep === 0}
              onClick={props.handleBack}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={async () => {
                await createCode({ number: value });
              }}
            >
              Re-send
            </Button>
          </div>
        </div>
      )}
    </>
  );
}
