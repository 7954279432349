import React from "react";
import AuthorisationComponent from "./blocks/Authorisation"


export default function Section(props) {
  console.log(props.section);
  const components = {
    AuthorisationComponent,
  };

  // const Component = components[props.section.type];

  return (
    // <Paper>
      <AuthorisationComponent {...props.section} route={props.route} />
      // <Component {...props.section} route={props.route} />
    // </Paper>
  );
}
