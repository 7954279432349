import axios from 'axios'
import {getAPIURL} from '../utils'

export async function getIntroducer(shortCode) {
    try {
        return await axios({
            method: 'get',
            url: `${getAPIURL() || 'http://localhost:8000'}/introducer?shortCode=${shortCode}`,
        });
    } catch (error) {
        console.error(error);
    }
}

export async function create(data) {
    try {
        return await axios({
            method: 'post',
            url: `${getAPIURL() || 'http://localhost:8000'}/introducer`,
            data
        });
    } catch (error) {
        console.error(error);
    }
}

export async function uploadLogo(file, abn) {
    console.log(abn)
    try {
        const data = new FormData()
        data.append('file', file)
        console.log(data)
        return await axios({
            method: 'post',
            url: `${getAPIURL() || 'http://localhost:8000'}/introducer/logo?abn=${abn}`,
            headers: { 'Content-Type': `multipart/form-data; boundary=${data._boundary}` },
            data
        });
    } catch (error) {
        console.error(error);
    }
}