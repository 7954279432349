import React, { useEffect, useState } from "react";
import { isUndefined, has, isNil } from "lodash";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { store, saveValue } from "../../redux/store";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import Button from "@material-ui/core/Button";

const FbleSlider = withStyles({
  root: {
    // color: '#52af77',
    height: 6,
  },
  thumb: {
    height: 30,
    width: 30,
    // border: "2px solid #757575",
    marginTop: -12,
    marginLeft: -12,
    boxShadow: "0 0 3px #fff",
    "&:focus, &:hover, &$active": {
      boxShadow: "0 0 3px #fff",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 9px)",
    // top: 7,
    // left: -3,
    "& *": {
      // background: 'transparent',
      // outline: "#000",
      // color: '#000',
    },
  },
  track: {
    height: 6,
    // borderRadius: 4,
  },
  rail: {
    height: 6,
    // borderRadius: 4,
  },
  mark: {
    // backgroundColor: '#bfbfbf',
    height: 8,
    width: 1,
    marginTop: 0,
  },
})(Slider);

export default function RadioButtonGroup(props) {
  const history = useHistory();
  const [marks, setMarks] = useState(props.marks.isArray ? props.marks : []);
  const [min, setMin] = useState(props.min);
  const [max, setMax] = useState(props.max);
  const [displaySlider, setDisplaySlider] = useState(false);
  const attributes = useSelector((state) => state.form);
  const value = attributes[props.object][props.name];
  const handleValue = (e, n) => {
    store.dispatch(saveValue({ [props.object]: { [props.name]: n } }));
    // handleDisplaySlider();
  };

  const criteria =
    props?.marks?.options?.[
    attributes?.[props.marks.criteria.object]?.[
    props?.marks?.criteria?.attribute
    ]
    ];

  const isDynamicMarks = () => {
    return has(props?.marks, "options");
  };

  const handleMarks = () => {
    if (isDynamicMarks()) {
      return setMarks(criteria);
    }

    return setMarks(props.marks);
  };

  const handleMin = () => {
    if (criteria) {
      return setMin(criteria?.[0]?.value);
    }
  };

  const handleMax = () => {
    if (criteria) {
      return setMax(criteria[criteria.length - 1].value);
    }
  };

  // const handleDisplaySlider = () => {
  //   const q = isUndefined(criteria) && isDynamicMarks();
  //   setDisplaySlider(q);
  // };

  // console.log(
  //   isUndefined(criteria) && isDynamicMarks(),
  //   isUndefined(isDynamicMarks()),
  //   criteria,
  //   isDynamicMarks(),
  //   props.name,
  //   props?.marks?.criteria?.attribute
  // );

  useEffect(() => {
    handleMin();
    handleMax();
    handleMarks();
  }, [value, criteria]);

  return (
    <>
      {!displaySlider && (
        <>
          {props.secondary && <label>{props.name}</label>}
          <FbleSlider
            {...props}
            marks={marks}
            max={max}
            min={min}
            step={props.step}
            value={value}
            onChange={handleValue}
            defaultValue={props.defaultValue}
            // valueLabelDisplay="on"
            // scale={(x) => x - 5}
            style={{ margin: "0 0 40px 0", width: "95%", alignSelf: "center" }}
          />
          {props.steps && (
            <div style={{ margin: 0 }}>
              {!props.secondary && <div>
                {!props.activeStep === 0 && (
                  <Button
                    variant="outlined"
                    disabled={props.activeStep === 0}
                    onClick={props.handleBack}
                    style={{ margin: "0 8px 0 0" }}
                  >
                    Back
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    console.log(props.activeStep);

                    if (props.activeStep === props.steps.length - 1)
                      return history.push(`/${props.route + 1}`);

                    props.handleNext();
                  }}
                >
                  {props.activeStep === props.steps.length - 1
                    ? "Finish"
                    : "Next"}
                  {/* Next */}
                </Button>
              </div>}
            </div>
          )}
        </>
      )}
    </>
  );
}
