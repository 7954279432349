import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { nanoid } from "nanoid";
import { store, saveValue } from "../../redux/store";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import DirectionsCar from "@material-ui/icons/DirectionsCar";
import BorderAll from "@material-ui/icons/BorderAll";
import LocalShipping from "@material-ui/icons/LocalShipping";
import DesktopMac from "@material-ui/icons/DesktopMac";
import Build from "@material-ui/icons/Build";
import Fastfood from "@material-ui/icons/Fastfood";
import LocalHospital from "@material-ui/icons/LocalHospital";
import Face from "@material-ui/icons/Face";
import Work from "@material-ui/icons/Work";

import Button from "@material-ui/core/Button";
import ToggleButton from "@material-ui/lab/ToggleButton";
import colours from "../../style/color";

const icons = {
  DirectionsCar,
  BorderAll,
  LocalShipping,
  DesktopMac,
  Build,
  Fastfood,
  LocalHospital,
  Face,
  Work
};

export default function RadioButtonGroup(props) {
  const history = useHistory();
  const value = useSelector((state) => state.form[props.object][props.name]);
  const handleValue = (e, n) => {
    console.log(e.target.value, n);
    store.dispatch(saveValue({ [props.object]: { [props.name]: n } }));
    if (props.pageType !== "quote") props.handleNext("button");
  };

  const toggleButtonStyle = {
    width: "100%",
  };

  const buttonContainerStyle = {
    background: colours.white,
    // boxShadow: `0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)`,
    "&:hover": {
      background: colours.darkGrey,
    },
  };

  const toggleButtonContentStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // height: "6rem",
    // height: "30px",
    // padding: "2px 0",
    color: colours.black,
    fontWeight: 600,
    lineHeight: 1.2
  };

  return (
    <>
      <Grid container spacing={3}>
        {props.options.map((option) => {

          const Icon = icons[option.icon];

          return (
            <Grid key={nanoid()} item xs={6}>
              <Paper key={nanoid()} style={buttonContainerStyle}>
                <ToggleButton
                  key={nanoid()}
                  selected={option.label === value}
                  onClick={handleValue}
                  value={option.label}
                  style={toggleButtonStyle}
                >
                  <div key={nanoid()} style={toggleButtonContentStyle}>
                    {/* {option.icon && <Icon key={nanoid()} />} */}
                    <p
                      key={nanoid()}
                      style={{
                        textTransform: "none",
                        letterSpacing: 0,
                        margin: "5px 0 0",
                        fontSize: "16px"
                      }}
                    >
                      {option.label}
                    </p>
                  </div>
                </ToggleButton>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
      {props.steps && (
        <div style={{ margin: "30px 0" }}>
          <div>
            <Button
              variant="outlined"
              disabled={props.activeStep === 0}
              onClick={props.handleBack}
              style={{ margin: "0 8px 0 0" }}
            >
              Back
            </Button>
          </div>
        </div>
      )}
    </>
  );
}
