import axios from "axios";
import { getAPIURL } from "../utils";
const url = getAPIURL() || "http://localhost:8000";

export async function createTransaction(applicantId, applicationId) {
  try {
    return await axios({
      method: "post",
      url: `${url}/identity-verification/`,
      data: {applicantId, applicationId}
    });
  } catch (error) {
    console.error(error);
  }
}

