import React, { useState } from "react";
import StepperComponent from "./blocks/Stepper";
import QuoteComponent from "./blocks/Quote";
import MobileNumberComponent from "./blocks/MobileNumber";
import IdentityComponent from "./blocks/Identity";
import AuthorisationComponent from "./blocks/Authorisation"
import ReviewComponent from "./blocks/Review"
import BasiqComponent from "./blocks/Basiq"


export default function Section(props) {
  console.log(props.section);
  const components = {
    StepperComponent,
    QuoteComponent,
    MobileNumberComponent,
    IdentityComponent,
    AuthorisationComponent,
    ReviewComponent,
    BasiqComponent
  };

  const Component = components[props.section.type];

  return (
    // <Paper>
      <Component {...props.section} route={props.route} />
    // </Paper>
  );
}
