import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Slider from "@material-ui/core/Slider";
import RadioButtonGroup from "../elements/RadioButtonGroup";
import TextFieldGroup from "../elements/TextFieldGroup";
import SliderGroup from "../elements/SliderGroup";
import TypographyFieldGroup from "../elements/TypographyFieldGroup";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import lender from "../../lender.json";
import { store, saveValue } from "../../redux/store";
import {
  calculateRepayments,
  evaluateCriteria,
  getBalloon,
  getMonthlyRate,
} from "../../utils";
import colours from "../../style/color";

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const Accordion = withStyles({
  root: {
    border: "none",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    // backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: "none",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

export default function StepperComponent(props) {
  const history = useHistory();
  const attributes = useSelector((state) => state.form);

  console.log(attributes);

  const elements = {
    TextField,
    Slider,
    RadioButtonGroup,
    TextFieldGroup,
    SliderGroup,
    TypographyFieldGroup,
  };

  useEffect(() => {}, []);

  return (
    <Grid container style={{ padding: "30px" }}>
      <Grid item xs={12} style={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="h5" style={{ margin: "0 0 30px" }}>
          Other Guarantors
        </Typography>
        <Typography style={{ margin: "0 0 20px" }}>
          Please add the contact details for the other directors/majority
          shareholders in your company.
        </Typography>
        {/* {Object.keys(attributes).map((categories) => {
          return (
            <div style={{ margin: "0 0 20px" }}>
              <Typography
                variant="h6"
                style={{ color: colours.babyBlue, margin: "0 0 5px" }}
              >
                {categories}
              </Typography>
              {Object.keys(attributes[categories]).map((attr) => {
                console.log(attr);
                if (attr !== "otc") {
                  // if (attributes[categories][attr] || attributes[categories][attr]?.length > 0) {
                  const spaceSeparatedLabel = attr.replace(/([A-Z])/g, " $1");
                  const formattedLabel =
                    spaceSeparatedLabel.charAt(0).toUpperCase() +
                    spaceSeparatedLabel.slice(1);

                  return (
                    <>
                      <Typography
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          borderBottom: "1px solid #333",
                          margin: "0 0 10px 0",
                        }}
                      >
                        {formattedLabel}:{" "}
                        <strong>{attributes[categories][attr]}</strong>
                      </Typography>
                    </>
                  );
                }
              })} 
            </div>
          );
        })}*/}

        <div style={{ margin: "30px 0" }}>
          <div>
            <Button
              style={{ margin: "0 8px 0 0" }}
              variant="outlined"
              // onClick={}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={props.showAuthorisation}
              onClick={() => history.push(`/${props.route + 1}`)}
            >
              Submit
            </Button>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
