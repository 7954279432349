import React from "react";
import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";

export default function PrivacySuccess(props) {
  return (
    <Grid
      container
      style={{
        padding: "30px 30px 0",
        height: "100vh",
      }}
    >
      <Grid item xs={12} style={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="h5" style={{ margin: "50px 0 0", fontSize: "32px" }}>
          Great! We've received your enquiry.
        </Typography>

        <Typography style={{ margin: "20px 0", fontSize: "20px" }}>
          All you need to do now is sit back and relax while someone from our team gets in touch in no time.
        </Typography>

        <Typography style={{ margin: "20px 0" }}>
          You may close this window.
        </Typography>
      </Grid>
    </Grid>
  );
}
