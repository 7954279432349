import React from "react";
import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";

export default function Authorisation(props) {
  return (
    <Grid
      container
      style={{
        padding: "30px 30px 0",
        height: "100vh",
      }}
    >
      <Grid item xs={12} style={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="h5">
          Thanks! We're verifying your identity as we speak.
        </Typography>

        <Typography style={{ margin: "20px 0" }}>
          We might ask you for a couple more things so keep your phone handy.
          Don't worry, not too much further!
        </Typography>

        <Typography style={{ margin: "20px 0" }}>
          You may close this window now. Have a great day :)
        </Typography>
      </Grid>
    </Grid>
  );
}
