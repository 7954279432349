import React, { useEffect, useState, useMemo, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { store, saveValue } from "../../redux/store";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import { debounce } from "lodash";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Close from "@material-ui/icons/Close";

import { getBusinessByABN, getBusinessByName } from "../../services/abr";

import color from "../../style/color";
import { readibleAbnString } from "../../utils";

const isDigitsOnly = /^(?=.*\d)[\d ]+$/;
const mobileSearchWrapperStyle = {
  position: "fixed",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  background: color.white,
  zIndex: 1000000,
  overflow: "scroll",
};

console.log(isMobile);

export default function BusinessSearchGroup(props) {
  const history = useHistory();
  const inputRef = React.useRef();

  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  // const value = useSelector((state) => state.form[props.object][props.name]);
  const [value, setValue] = useState(null);

  const setEntityDetails = async (data) => {
    const fullBusinessDetails = await getBusinessByABN(data.Abn);
    console.log(fullBusinessDetails);

    store.dispatch(
      saveValue({
        Entity: {
          entityName: fullBusinessDetails.EntityName,
          abn: fullBusinessDetails.Abn,
          acn: fullBusinessDetails.Acn,
          gst: fullBusinessDetails.Gst,
          entityTypeName: fullBusinessDetails.EntityTypeName,
          businessNames: fullBusinessDetails.BusinessName,
          timeInBusiness:
            fullBusinessDetails.Gst ||
            fullBusinessDetails.AbnStatusEffectiveFrom,
        },
      })
    );
  };

  const debounced = debounce(async (inputValue) => {
    if (inputValue) {
      setLoading(true);
      if (isDigitsOnly.test(inputValue)) {
        const businessByABN = await getBusinessByABN(inputValue);
        setLoading(false);
        if (businessByABN.Abn.length > 1) {
          return setOptions([businessByABN]);
        }
      }

      const businesses = await getBusinessByName(inputValue);
      if (businesses?.Names?.length > 0) {
        setLoading(false);
        return setOptions(
          businesses.Names.map((i) => {
            // return { label: `${i.Name} ${i.Abn}`, Abn: i.Abn, value: i };
            return i;
          })
        );
      }

      setOptions([
        {
          Name: "No results found",
        },
      ]);
    }
  }, 300);

  useEffect(() => {
    debounced(inputValue);

    return () => {
      debounced.cancel();
    };
  }, [value, inputValue]);

  return (
    <>
      <Autocomplete
        autoComplete
        name={props.name}
        {...props}
        value={value}
        defaultValue={props.defaultValue}
        onChange={(event, newValue) => {
          if (newValue) {
            setEntityDetails(newValue);
            setValue(newValue?.Name || newValue?.EntityName);

            return;
          }

          setValue("");
        }}
        filterOptions={(x) => x}
        onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
        getOptionLabel={(option) => option.Name}
        inputValue={value}
        options={options}
        loading={loading}
        renderInput={(params) => (
          <TextField
            onClick={() => {
              if (isMobile) setMobileView(true);
            }}
            variant="outlined"
            {...params}
            // label="Asynchronous"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      {props.steps && (
        <div style={{ margin: "30px 0" }}>
          <div>
            <Button
              variant="outlined"
              disabled={props.activeStep === 0}
              onClick={props.handleBack}
              style={{ margin: "0 8px 0 0" }}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={props.required && (value < 1 || !value)}
              onClick={() => {
                if (props.activeStep === props.steps.length - 1)
                  return history.push(`/${props.route + 1}`);
                props.handleNext();
              }}
            >
              {!props.required && value < 1
                ? "Skip"
                : props.activeStep === props.steps.length - 1
                ? "Finish"
                : "Next"}
            </Button>
          </div>
        </div>
      )}
      {mobileView && (
        <Box style={mobileSearchWrapperStyle}>
          <TextField
            inputRef={inputRef}
            autoFocus="true"
            onChange={(event) => {
              setLoading(true);
              console.log(event.target.value);
              setInputValue(event.target.value);
            }}
            value={inputValue}
            style={{
              width: "100%",
              position: "fixed",
              background: color.white,
              zIndex: 1000000,
            }}
            InputProps={{
              style: {
                padding: "10px 20px",
              },
              startAdornment: (
                <ArrowBack
                  style={{ margin: "0 10px 0 0" }}
                  onClick={() => setMobileView(false)}
                />
              ),
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    inputValue.length > 0 && (
                      <Close
                        onClick={() => {
                          setInputValue("");
                          setOptions([]);
                          inputRef.current.focus();
                        }}
                      />
                    )
                  )}
                  {/* {params.InputProps.endAdornment} */}
                </React.Fragment>
              ),
            }}
          />
          <Box style={{ marginTop: "52px" }}>
            <List aria-label="secondary mailbox folders">
              {options.map((option) => (
                <ListItem button>
                  <ListItemText
                    style={{ padding: "0 28px" }}
                    primary={option.Name || option.EntityName}
                    secondary={readibleAbnString(option.Abn)}
                    onClick={() => {
                      setEntityDetails(option);
                      setValue(option.Name || option.EntityName);
                      setMobileView(false);
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        </Box>
      )}
    </>
  );
}
