import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import RadioButtonGroup from "../elements/RadioButtonGroup";
import TextFieldGroup from "../elements/TextFieldGroup";
import SliderGroup from "../elements/SliderGroup";
import lender from "../../lender.json";
import { store, saveValue } from "../../redux/store";

export default function MobileNumberComponent(props) {
  const attributes = useSelector((state) => state.form);

  const elements = {
    TextField,
    Slider,
    RadioButtonGroup,
    TextFieldGroup,
    SliderGroup,
  };

  const [activeStep, setActiveStep] = React.useState(0);
  const [repayments, setRepayments] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  console.log(props)
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography>{props.name}</Typography>

        {props.components.map((item) => {
          const Component = elements[item.componentType];
          return (
            <>
              <div style={{ margin: "0 0 10px" }}>
                <Typography>
                  {item.heading}
                  {/* {item.heading === "Balloon" && balloon} */}
                </Typography>
                <Typography>{item.description}</Typography>
              </div>

              <Component section={props.name} {...item} />
            </>
          );
        })}

        {activeStep === props.components.length && (
          <Paper square elevation={0}>
            <Typography>All steps completed - you&apos;re finished</Typography>
            <Button onClick={handleReset}>Reset</Button>
          </Paper>
        )}
      </Grid>
    </Grid>
  );
}
