export function getAPIURL() {
  const subdomain = window.location.host.split(".")[1]
    ? window.location.host.split(".")[0]
    : false;
  console.log(subdomain);
  if (subdomain === "you") return "https://api.financeable.com.au";
  if (subdomain === "test") return "https://dev.api.financeable.com.au";
  if (subdomain === "clownfish-app-cs7dn") return "https://dev.api.financeable.com.au";
  if (subdomain === "apply") return "https://ggf-server-ums3s.ondigitalocean.app";

  console.log(process.env.ENV, subdomain);
  return "http://localhost:8000";
}

export function parseJSONP(jsonp) {
  const f = new Function("callback", jsonp);
  let data;
  f((callback) => (data = callback));

  return data;
}

export function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  let byteString = atob(dataURI.split(",")[1]);

  // separate out the mime component
  let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to an ArrayBuffer
  let ab = new ArrayBuffer(byteString.length);
  let ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeString });
}

export function calculateCompoundInterest(amount, rate, frequency) {
  const time = frequency / 12;

  return amount * Math.pow(1 + rate / 100 / frequency, time * frequency);
}

// export function calculateRepayments(
//   rate,
//   term,
//   presentValue,
//   futureValue,
//   type
// ) {
//   /*
//    * ir   - interest rate per month
//    * np   - number of periods (months)
//    * pv   - present value
//    * fv   - future value
//    * type - when the payments are due:
//    *        0: end of the period, e.g. end of month (default)
//    *        1: beginning of period
//    */
//   var pmt, pvif;

//   futureValue || (futureValue = 0);
//   type || (type = 0);

//   if (rate === 0) return -(presentValue + futureValue) / term;

//   pvif = Math.pow(1 + rate, term);
//   pmt = -rate * (presentValue * pvif + futureValue) / (pvif - 1);

//   if (type === 1) pmt /= (1 + rate);

//   return pmt;
// }

export function calculateRepayments(ir, np, pv, fv, type) {
  /*
   * ir   - interest rate per month
   * np   - number of periods (months)
   * pv   - present value
   * fv   - future value
   * type - when the payments are due:
   *        0: end of the period, e.g. end of month (default)
   *        1: beginning of period
   */
  var pmt, pvif;

  fv || (fv = 0);
  type || (type = 0);

  if (ir === 0) return -(pv + fv) / np;

  pvif = Math.pow(1 + ir, np);
  pmt = (-ir * (pv * pvif + fv)) / (pvif - 1);

  if (type === 1) pmt /= 1 + ir;

  return pmt;
}

export function getMonthlyRate(rate) {
  return rate / 100 / 12;
}

export function getBalloon(balloon, loanAmount) {
  return (balloon / 100) * loanAmount;
}

export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function convertToNumber(val) {
  if (typeof val === "string") return Number(val.replace(/[^0-9.-]+/g, ""));
  return val;
}

export function debounce(func, wait, immediate) {
  let timeout;
  return function () {
    let context = this,
      args = arguments;
    let later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    let callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export function evaluateCriteria(productCriteria, objects) {
  console.log(productCriteria, objects);
  return productCriteria.reduce((boolean, criteria) => {
    // console.log(criteria.attribute);
    // console.log(criteria.value);
    // console.log(objects[criteria.object][criteria.attribute]);
    // console.log(
    //   conditions[criteria.condition](
    //     criteria.value,
    //     objects[criteria.object][criteria.attribute]
    //   )
    // );
    return conditions[criteria.condition](
      criteria.value,
      objects[criteria.object][criteria.attribute]
    )
      ? boolean
      : false;
  }, true);
}

export const conditions = {
  is: (attr) => {
    return attr;
  },
  equal: (value, attr) => {
    return value === attr;
  },
  lessThan: (value, attr) => {
    return attr < value;
  },
  greaterThan: (value, attr) => {
    return attr > value;
  },
  lessThanOrEqualTo: (value, attr) => {
    return attr < value || value === attr;
  },
  greaterThanOrEqualTo: (value, attr) => {
    return attr > value || value === attr;
  },
  between: ({ min, max }, attr) => {
    return attr > min && attr < max;
  },
  hasOne: (value, attr) => {
    console.log(value, attr)
    return value.includes(attr);
  },
};

export function getInitialState(form) {
  const initialState = {};

  for (let item in form) {
    for (let formItem in form[item].components) {
      const attr = {
        [form[item].components[formItem].name]:
          form[item].components[formItem].defaultValue,
      };
      initialState[form[item].components[formItem].object] = Object.assign(
        {},
        initialState[form[item].components[formItem].object],
        attr
      );

      if (form[item].components[formItem].secondary) {
        const attr = {
          [form[item].components[formItem].name]:
            form[item].components[formItem].defaultValue,
        };
        initialState[form[item].components[formItem].object] = Object.assign(
          {},
          initialState[form[item].components[formItem].object],
          attr
        );
      }
    }
  }

  return initialState;
}

export function readibleAbnString(n) {
  if (n)
    return `${n?.substring(0, 2)} ${n?.substring(2, 5)} ${n?.substring(
      5,
      8
    )} ${n?.substring(8, 11)}`;
}