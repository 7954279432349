import axios from "axios";
import { getAPIURL } from "../utils";

console.log(process.env.NODE_ENV);
console.log("url", getAPIURL());

export async function createVerification(data) {
  try {
    return await axios({
      method: "post",
      url: `${getAPIURL() || "http://localhost:8000"}/email/verify`,
      data,
    });
  } catch (error) {
    console.error(error);
    return error;
  }
}

export async function verify(data) {
  try {
    return await axios({
      method: "get",
      url: `${getAPIURL() || "http://localhost:8000"}/email/verify?aid=${
        data.applicantId
      }&code=${data.code}`,
      data,
    });
  } catch (error) {
    console.error(error);
    return error;
  }
}

export async function sendSign(data) {
  try {
    return await axios({
      method: "post",
      url: `${getAPIURL() || "http://localhost:8000"}/signatories`,
      data,
    });
  } catch (error) {
    console.error(error);
    return error;
  }
}
