import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

import { store, saveValue } from "../../redux/store";
import { createVerification } from "../../api/email";

export default function EmailFieldGroup(props) {
  const history = useHistory();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const applicant = useSelector((state) => state.form.Applicant);
  const value = useSelector((state) => state.form[props.object][props.name]);
  console.log(value);

  const handleSaveEmail = async () => {
    //TODO: validation for email
    store.dispatch(
      saveValue({
        [props.object]: {
          status: "not verified",
          emailType: "primary",
        },
      })
    );
    const response = await createVerification({ applicant, email: value });
    console.log(response.status);

    if (response.status >= 200) {
      setShowConfirmation(true);
      // if (props.activeStep === props.steps.length - 1)
      //   return history.push(`/${props.route + 1}`);
    }
  };

  const handleValue = (e) => {
    console.log(e.target.value);
    store.dispatch(
      saveValue({
        [props.object]: {
          [props.name]: e.target.value,
        },
      })
    );
  };

  const startAdornment = props.startAdornment && (
    <InputAdornment position="start">{props.startAdornment}</InputAdornment>
  );

  const endAdornment = props.endAdornment && (
    <InputAdornment position="end">{props.endAdornment}</InputAdornment>
  );

  return (
    <>
      {!showConfirmation && (
        <>
          <TextField
            name={props.name}
            {...props}
            value={value}
            // defaultValue={state.form[props.object][props.defaultValue]}
            onChange={handleValue}
            InputProps={{
              startAdornment,
              endAdornment,
            }}
            style={{ margin: " 0 0 30px 0" }}
          />
        </>
      )}
      {showConfirmation && (
        <Box>
          <Typography>
            Please check your email inbox and verify your email address: {value}
          </Typography>
          <br></br>
          <Typography>
            Once verified, we will send you a copy of your application and let
            you know the outcome of your application to your email.
          </Typography>
        </Box>
      )}
      {props.steps && (
        <div style={{ margin: "30px 0" }}>
          <div>
            <Button
              disabled={props.activeStep === 0}
              onClick={props.handleBack}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={async () => {
                // !showConfirmation
                //   ? await handleSaveEmail()
                //   : history.push(`/${props.route + 1}`);
                history.push(`/${props.route + 1}`)
              }}
            >
              Next
              {/* {props.activeStep === props.steps.length - 1 ? "Finish" : "Next"} */}
              {/* {value ? "Next" : props.buttonLabel || "Next"} */}
            </Button>
          </div>
        </div>
      )}
    </>
  );
}
