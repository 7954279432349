import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Slider from "@material-ui/core/Slider";
import RadioButtonGroup from "../elements/RadioButtonGroup";
import TextFieldGroup from "../elements/TextFieldGroup";
import SliderGroup from "../elements/SliderGroup";
import TypographyFieldGroup from "../elements/TypographyFieldGroup";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import lender from "../../lender.json";
import { store, saveValue } from "../../redux/store";
import {
  calculateRepayments,
  evaluateCriteria,
  getBalloon,
  getMonthlyRate,
} from "../../utils";

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const Accordion = withStyles({
  root: {
    border: 'none',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    // backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: 'none',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

export default function StepperComponent(props) {
  const history = useHistory();
  const { Application } = useSelector((state) => state.form);

  const elements = {
    TextField,
    Slider,
    RadioButtonGroup,
    TextFieldGroup,
    SliderGroup,
    TypographyFieldGroup,
  };
  
  useEffect(() => {}, []);

  return (
    <Grid container style={{ padding: "10px" }}>
      <Grid item xs={12} style={{ display: "flex", flexDirection: "column" }}>
        

        <div style={{ margin: "30px 0" }}>
          <div>
            <Button
            // onClick={}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={props.showAuthorisation}
              onClick={() => history.push(`/${props.route + 1}`)}
            >
              Next
            </Button>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
