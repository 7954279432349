import axios from "axios";
import { getAPIURL } from "../utils";
const url = getAPIURL() || "http://localhost:8000";

export async function submitApplication(data) {
  try {
    return await axios({
      method: "post",
      url: `${url}/application/submit`,
      data,
    });
  } catch (error) {
    console.error(error);
  }
}

export async function startApplication(data) {
  try {
    return await axios({
      method: "post",
      url: `${url}/application/start`,
      data,
    });
  } catch (error) {
    console.error(error);
  }
}

export async function updateApplication(data) {
  try {
    return await axios({
      method: "post",
      url: `${url}/application/update`,
      data,
    });
  } catch (error) {
    console.error(error);
  }
}
