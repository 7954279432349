import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Button,
  Heading,
  MaskedInput,
  RadioButtonGroup,
  FormField,
  Select,
  Text,
  TextInput,
  Spinner,
} from "grommet";
import { Previous, Next } from "grommet-icons";
import { create, uploadLogo } from "../api/introducer";
import { useDropzone } from "react-dropzone";
import { dataURItoBlob } from "../utils";
// import { containerStyle } from "../../style";

const storage = window.sessionStorage;

export default function GetQuote(props) {
  const [creatingSupplier, setCreatingSupplier] = useState(false);
  const [supplierName, setSupplierName] = useState();
  const [supplierAbn, setSupplierAbn] = useState();
  const [supplierAddress, setSupplierAddress] = useState();
  const [supplierPhoneNumber, setSupplierPhoneNumber] = useState();
  const [supplierContactPerson, setSupplierContactPerson] = useState();
  const [link, setLink] = useState("");
  const [complete, setComplete] = useState(false);

  const onChangeLink = async (value) => {
    setLink(value.toLowerCase());
    storage.setItem(
      "link",
      `https://you.are.financeable.com.au/in/` + value.toLowerCase()
    );
    storage.setItem("shortCode", value.toLowerCase());
  };

  const onChangeSupplierName = async (event) => {
    setSupplierName(event.target.value);
    storage.setItem("name", event.target.value);
    generateLink(event.target.value);
  };
  const onChangeSupplierAbn = async (event) => {
    setSupplierAbn(event.target.value);
    storage.setItem("ABN", event.target.value);
  };
  const onChangeSupplierAddress = async (event) => {
    setSupplierAddress(event.target.value);
    storage.setItem("address", event.target.value);
  };
  const onChangeSupplierPhoneNumber = async (event) => {
    setSupplierPhoneNumber(event.target.value);
    storage.setItem("phone", event.target.value);
  };
  const onChangeSupplierContactPerson = async (event) => {
    setSupplierContactPerson(event.target.value);
    storage.setItem("contacts", event.target.value);
  };
  const [uploadFile, setUploadFile] = useState(null);
  const [dataUri, setDataUri] = useState("");

  const [imagePreview, setImagePreview] = useState("");

  function generateLink(supplierName) {
    if (supplierName) {
      const nameLength = supplierName.split(" ").length;
      console.log(nameLength);
      if (nameLength > 3) {
        const letters = supplierName.match(/\b(\w)/g);
        const acronym = letters.join("");
        return onChangeLink(acronym.substring(0, 4));
      }

      return onChangeLink(supplierName.substring(0, 3));
    }
  }

  function MyDropzone() {
    const onDrop = useCallback((acceptedFiles) => {
      console.log(acceptedFiles);
      setImagePreview(URL.createObjectURL(acceptedFiles[0]));
      setUploadFile(acceptedFiles[0]);
    }, []);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
    });

    return (
      <Box
        {...getRootProps()}
        background="#efefef"
        // width="550px"
        // height="320px"
        justify="center"
        direction="column"
      >
        <input {...getInputProps()} />
        <Button
          label="Upload an image"
          size="small"
          onClick={() => {
            // setShowTakePhoto(true);
            // setShowUploadPhoto(false);
            // setShowFillOut(false);
          }}
        />
        {imagePreview && (
          <Box width="100%" overflow="hidden" justify="center" align="center">
            <img
              src={imagePreview}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          </Box>
        )}
      </Box>
    );
  }

  const ImagePreview = ({ dataUri, isFullscreen }) => {
    let classNameFullscreen = isFullscreen
      ? "demo-image-preview-fullscreen"
      : "";

    return (
      <div className={"demo-image-preview " + classNameFullscreen}>
        <img
          src={dataUri}
          style={{
            "-webkit-transform": "scaleX(-1)",
            transform: "scaleX(-1)",
            width: "100%",
            height: "100%",
            objectFit: "contain",
          }}
        />
      </div>
    );
  };

  async function handleUploadLogo() {
    if (uploadFile) {
      const res = await uploadLogo(uploadFile, storage.ABN);
    }
  }

  const isFullscreen = false;

  return (
    <Box>
      <Box
        background="#ffffff"
        pad="20px"
        alignSelf="center"
        justify="center"
        animation={{
          type: "fadeIn",
          delay: 0,
          duration: 600,
          size: "large",
        }}
      >
        <Box>
          <Heading level="2" margin={{ bottom: "30px" }}>
            Supplier details
          </Heading>
        </Box>
        <Box gap="medium">
          <Box>
            <Text>Name</Text>
            <TextInput value={supplierName} onChange={onChangeSupplierName} />
          </Box>
          <Box>
            <Text>Link</Text>
            <TextInput
              maxLength="4"
              style={{ paddingLeft: "234px" }}
              icon={`https://you.are.financeable.com.au/in/`}
              value={link}
              onChange={(event) => onChangeLink(event.target.value)}
            />
          </Box>
          <Box>
            <Text>ABN</Text>
            <TextInput value={supplierAbn} onChange={onChangeSupplierAbn} />
          </Box>
          <Box>
            <Text>Address</Text>
            <TextInput
              value={supplierAddress}
              onChange={onChangeSupplierAddress}
            />
          </Box>
          <Box>
            <Text>Phone number</Text>
            <TextInput
              value={supplierPhoneNumber}
              onChange={onChangeSupplierPhoneNumber}
            />
          </Box>
          <Box>
            <Text>Contact person/s email (comma separated)</Text>
            <TextInput
              value={supplierContactPerson}
              onChange={onChangeSupplierContactPerson}
            />
          </Box>
          <Box>
            <Text>Add logo</Text>
            <MyDropzone />
            {dataUri && (
              <ImagePreview dataUri={dataUri} isFullscreen={isFullscreen} />
            )}
          </Box>
          {complete && <Text color="#17E34D">Supplier added!</Text>}
          <Box justify="between" direction="row" margin={{ vertical: "20px" }}>
            <Button
              primary
              label={
                creatingSupplier ? (
                  <span style={{ display: "flex", flexDirection: "row" }}>
                    Creating{" "}
                    <Spinner color="#ffffff" style={{ marginLeft: "15px" }} />
                  </span>
                ) : (
                  "Create supplier"
                )
              }
              onClick={async () => {
                setCreatingSupplier(true);
                await handleUploadLogo();
                const data = {...storage}
                const emails = data.contacts.split(',')
                data.emails = emails.map(s => s.trim());
                console.log(data, data.emails)
                await create(data);
                storage.clear();
                setLink('');
                setSupplierName('');
                generateLink('');
                setSupplierAbn('');
                setSupplierAddress('');
                setSupplierPhoneNumber('');
                setSupplierContactPerson('');
                setCreatingSupplier(false);
                setComplete(true)
              }}
              {...props}
              icon={<Next />}
              reverse
              disabled={creatingSupplier}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
