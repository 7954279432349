import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { store, saveValue } from "../../redux/store";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

export default function RadioButtonGroup(props) {
  const [defaultValue, setDefaultValue] = useState(props.defaultValue);
  const history = useHistory();
  const value = useSelector((state) => state.form[props.object][props.name]);
  const handleValue = (v) => {
    console.log(parseInt(v, 10), value)
    if (props.type === "number") return store.dispatch(saveValue({ [props.object]: { [props.name]: parseInt(v, 10) } }));

    return store.dispatch(saveValue({ [props.object]: { [props.name]: v } }))
  };

  const startAdornment = props.startAdornment && (
    <InputAdornment position="start">{props.startAdornment}</InputAdornment>
  );

  const endAdornment = props.endAdornment && (
    <InputAdornment position="end">{props.endAdornment}</InputAdornment>
  );

  useEffect(() => {
    console.log(props.defaultValue);
    if (!value || value.length < 0) handleValue(defaultValue);
  }, [defaultValue]);

  return (
    <>
      <TextField
        name={props.name}
        {...props}
        value={value}
        defaultValue={props.defaultValue}
        onChange={(event) => handleValue(event.target.value)}
        InputProps={{
          startAdornment,
          endAdornment,
          style: {
            fontSize: "1.5em"
          }
        }}
      />
      {props.steps && (
        <div style={{ margin: "30px 0" }}>
          {!props.secondary && <div>
            <Button
              variant="outlined"
              disabled={props.activeStep === 0}
              onClick={props.handleBack}
              style={{ margin: "0 8px 0 0" }}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={props.required && (!value && value !== 0)}
              onClick={() => {
                if (props.activeStep === props.steps.length - 1)
                  return history.push(`/${props.route + 1}`);
                props.handleNext();
              }}
            >
              {!props.required && (!value && value !== 0)
                ? "Skip"
                : props.activeStep === props.steps.length - 1
                  ? "Next"
                  : "Next"}
            </Button>
          </div>}
        </div>
      )}
    </>
  );
}
