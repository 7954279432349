import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import RadioButtonGroup from "../elements/RadioButtonGroup";
import TextFieldGroup from "../elements/TextFieldGroup";
import SliderGroup from "../elements/SliderGroup";
import TypographyFieldGroup from "../elements/TypographyFieldGroup";
import lender from "../../azora-commercial-product.json";
import { store, saveValue } from "../../redux/store";
import { updateApplication } from "../../api/application";
import colours from "../../style/color";
import {
  calculateRepayments,
  evaluateCriteria,
  getBalloon,
  getMonthlyRate,
} from "../../utils";
import { colors } from "@material-ui/core";

export default function QuoteComponent(props) {
  const history = useHistory();
  const product = lender.product;
  const attributes = useSelector((state) => state.form);
  let { term, loanAmount, balloon, deposit = 0, brokerage, creditQuality, loanType, rate } =
    attributes.Quote;
  const { value = 0 } = attributes.Asset;

  deposit = deposit || 0

  const handleResetQuote = () => {
    history.push(`/${props.route - 1}`);
  };

  const elements = {
    TextField,
    Slider,
    RadioButtonGroup,
    TextFieldGroup,
    SliderGroup,
    TypographyFieldGroup,
  };

  const [activeStep, setActiveStep] = React.useState(0);
  const [repayments, setRepayments] = React.useState(0);

  const handleRepayments = (repayments) => {
    store.dispatch(saveValue({ Quote: { repayments } }));
  };

  const handleRate = (rate) => {
    store.dispatch(saveValue({ Quote: { rate } }));
  };

  const calculateQuote = () => {
    store.dispatch(
      saveValue({
        Quote: { loanAmount: value - deposit },
      })
    );

    // const matchedProductTier = product.productTiers.filter((tier) => {
    //   if (evaluateCriteria(tier.productCriteria, attributes)) {
    //     return tier.rate;
    //   }

    // });

    // const monthlyRate = getMonthlyRate(matchedProductTier[0]?.rate);
    let rate = getRate()
    if (creditQuality === "Yes") rate += 2
    handleRate(rate)
    console.log("rate", rate)
    // handleRate(matchedProductTier[0]?.rate);
    // console.log(balloon, loanAmount)
    // const balloonAmount = getBalloon(balloon, loanAmount);
    console.log(rate, term * 12, -value + deposit || 0);
    const rpmts = calculateRepayments(
      rate / 100 / 12,
      term * 12,
      -value + deposit,
      0,
      1
    );
    // const weeklyRepayments = rpmts / 4.33;
    console.log(-rpmts || 0);
    console.log((Math.round(rpmts * 100) / 100).toFixed(2) || 0);
    setRepayments(-rpmts ? (Math.round(rpmts * 100) / 100).toFixed(2) : 0);
    handleRepayments(-rpmts ? (Math.round(rpmts * 100) / 100).toFixed(2) : 0);
  };

  function getRate() {
    const LVR = 1 - deposit / value

    if (LVR < 0.8) return 4.39
    if (LVR >= 0.8 && LVR < 0.9) return 5.39

    return 6.39
  }

  const handleNext = async () => {
    await updateApplication(attributes);
    history.push(`/${props.route + 1}`);
  };

  useEffect(() => {
    console.log(value, deposit);

    calculateQuote();
    handleRepayments(repayments);
    console.log(repayments);
  }, [value, deposit, term, loanAmount, balloon, repayments, brokerage, creditQuality, loanType]);

  return (
    <Grid
      style={{
        padding: "30px 30px 0",
      }}
    >
      <Grid item xs={12} style={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="h4" style={{ margin: "0 0 30px 0" }}>
          {props.name}
        </Typography>

        {props.components.map((item) => {
          const Component = elements[item.componentType];
          console.log("conditional", item?.conditional?.condition.value, attributes.Quote[item?.conditional?.condition.attribute])
          if (Component && item?.conditional?.condition.value == attributes.Quote[item?.conditional?.condition.attribute]) {
            return (
              <div style={{ margin: "0 0 20px" }}>
                <div style={{ margin: "0 0 10px" }}>
                  <Typography>
                    {item.heading}
                    {/* {item.heading === "Balloon" && balloon} */}
                  </Typography>
                  <Typography>{item.description}</Typography>
                </div>

                <Component
                  section={props.name}
                  {...item}
                  value={attributes.Quote[item.name]}
                  props={item}
                />
              </div>
            );
          }
        })}

        {/* <div style={{ margin: "10px 0" }}>
          <Typography style={{}}>Your repayments:</Typography>
          <Typography style={{ fontSize: "28px" }}>${repayments} <span style={{ fontSize: "16px" }}>per month</span></Typography>
        </div>

        <div style={{ margin: "0 0" }}>
          <Typography style={{}}>Your rate:</Typography>
          <Typography style={{ fontSize: "28px" }}>{rate}%</Typography>
        </div> */}

        <div style={{ margin: "30px 0" }}>
          <div>
            <Button
              variant="outlined"
              style={{ margin: "0 8px 0 0" }}
              onClick={() => handleResetQuote()}
            >
              New Quote
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={props.required && (value < 1 || !value)}
              onClick={async () => await handleNext()}
            >
              Check Your Eligability
            </Button>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
