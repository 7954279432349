import axios from "axios";
import { getAPIURL } from "../utils";

console.log(process.env.NODE_ENV);
console.log("url", getAPIURL());

export async function createCode(data) {
  try {
    return await axios({
      method: "post",
      url: `${getAPIURL() || "http://localhost:8000"}/phone-number/verify`,
      data,
    });
  } catch (error) {
    console.error(error);
    return error;
  }
}

export async function verifyCode(data) {
  try {
    return await axios({
      method: "get",
      url: `${getAPIURL() || "http://localhost:8000"}/phone-number/verify?aid=${data.applicantId}&code=${data.code}`,
      data,
    });
  } catch (error) {
    console.error(error);
    return error;
  }
}

export async function sendSign(data) {
  try {
    return await axios({
      method: "post",
      url: `${getAPIURL() || "http://localhost:8000"}/signatories`,
      data,
    });
  } catch (error) {
    console.error(error);
    return error;
  }
}
