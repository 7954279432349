import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import lender from "../../lender.json";
import { store, saveValue } from "../../redux/store";
import { signPrivacy } from "../../api/signPrivacy";
import { submitApplication } from "../../api/application";
import { submitLead } from "../../api/lead";

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const Accordion = withStyles({
  root: {
    border: "none",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    // backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: "none",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

export default function Authorisation(props) {
  const history = useHistory();
  const attributes = useSelector((state) => state.form);
  const [showAuthorisation, setShowAuthorisation] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const params = Object.fromEntries(urlSearchParams.entries());
  console.log({ params });
  const customerId = params.cid;
  const applicationId = params.aid;

  const handleCheck = (event) => {
    setChecked(event.target.checked);
  };

  const handleShowAuthorisation = () => {
    setShowAuthorisation((prev) => !prev);
  };

  useEffect(() => { }, []);

  return (
    <Grid
      container
      style={{
        padding: "30px 30px 0",
        // background: "#000000",
        // color: "#ffffff",
        height: "100vh",
      }}
    >
      <Grid item xs={12} style={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="h5">Your credit</Typography>

        <Typography level="3" style={{ margin: "20px 0" }}>
         To give you the most accurate rate, we may check your credit history as part of your enquiry. It will not affect your credit score.
        </Typography>

        <Typography level="3" style={{ margin: "20px 0" }}>
          Please read the Privacy statement and agree before submitting your enquiry.
        </Typography>

        <Accordion style={{ boxShadow: "none", margin: "20px 0", padding: 0 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ padding: 0 }}
          >
            <Typography>Open Privacy Agreement</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: "column" }}>
            <Typography margin={{ bottom: "20px" }}>
              <strong>21st Century Finance</strong>
            </Typography>
            <Typography margin={{ bottom: "20px" }}>
              Ph: (02) 8817 7780 ABN: 87 000 903 257 Australian Credit License:
              387822 PRIVACY DISCLOSURE STATEMENT & CONSENT
            </Typography>
            <p style={{ marginBottom: "20px" }}>Overview</p>

            <p style={{ marginBottom: "20px" }}>
              21st Century Finance Pty Ltd, (‘we’, ‘us’,
              ‘our’) collects information about you for the purposes you agree
              to in this Privacy Disclosure Statement and Consent. When you sign
              below, you agree we can, consistently with Australia’s privacy and
              credit reporting laws, collect, use and exchange credit and
              personal information about you for those purposes.
            </p>

            <p style={{ marginBottom: "5px" }}>
              Privacy Disclosure Statement and Consent
            </p>
            <p style={{ marginBottom: "5px" }}>
              We are collecting credit and personal information (information)
              about you, as applicable:
            </p>
            <ul>
              <li>
                To provide you, or a company of which you are a director, with:
                <ul>
                  <li>
                    credit for personal, household, domestic or residential
                    investment purposes; or Commercial credit for business
                    purposes; or Other services stated in this Privacy
                    Disclosure Statement and Consent (Consent); or
                  </li>
                </ul>
              </li>
              <li>In relation to a guarantee you will provide.</li>
            </ul>

            <p style={{ marginBottom: "20px" }}>
              We require the information we collect from you, or your broker on
              your behalf, to assess your credit or guarantor application or the
              credit application of a company of which you are a director,
              source any required insurances and to manage any credit contract
              that results. If you do not provide the information sought we may
              be unable to process your application, or the company’s
              application, or we may be limited in the other services we can
              offer you or the company.
            </p>

            <p style={{ marginBottom: "20px" }}>
              We are also required to collect your personal information to
              comply with our obligations under Australian law, including the
              <em>
                Anti-Money Laundering and Counter-Terrorism Financing Act 2006
                (Cth).
              </em>
            </p>

            <p style={{ marginBottom: "5px" }}>
              Your information – Collection and Credit Reporting Body
              Disclosures
            </p>
            <p style={{ marginBottom: "5px" }}>
              When we collect information from you in the credit application
              process, we use that information in a number of ways to assess
              your credit application and to manage any credit contract that
              results. We may:
            </p>
            <ul>
              <li>
                Disclose your information to Veda Advantage Information Services
                and Solutions Limited, a credit reporting body (CRB)
              </li>
              <li>
                Use information the CRB provides to assist us assess your credit
                or guarantor application
              </li>
              <li>
                Disclose your credit information to your introducer to assist in
                the application process
              </li>
              <li>
                Notify the CRB of any overdue payments, provided they are more
                than 60 days overdue, we have attempted to collect the payment
                and we have notified you of our intention to do so
              </li>
              <li>
                Notify the CRB of a serious credit infringement if we have
                reasonable grounds to believe your fraudulently obtained, or
                attempted to obtain, credit from us or that you have shown an
                intention to evade your obligations under the contract with us
                <ul>
                  <li>
                    We will only do this if we have not been able to contact you
                    over a 6 month period
                  </li>
                </ul>
              </li>
              <li>
                Ask the CRB to assess your eligibility to receive direct
                marketing material from us
              </li>
              <li>
                Disclose your credit and personal information to any person we
                are authorised or required to by law, such as under the
                Anti-Money Laundering and Counter-Terrorism Financing Act 2006
                (Cth)or by government or law enforcement agencies or regulators,
                or where you have provided us with consent.
              </li>
            </ul>

            <p style={{ marginBottom: "20px" }}>
              The CRB may include your information in reports to other credit
              providers to assist them in assessing your credit worthiness.
            </p>

            <p style={{ marginBottom: "20px" }}>
              The information we obtain from you is used, subject to compliance
              with Australia’s privacy and credit reporting laws, only for the
              purposes listed in this Consent and is not disclosed to any other
              person except with your permission or as permitted, or required,
              by law.
            </p>

            <p style={{ marginBottom: "5px" }}>Your rights</p>
            <p style={{ marginBottom: "5px" }}>You have the right to ask:</p>
            <ul style={{ marginBottom: "20px" }}>
              <li>
                us to provide you with all the information we hold about you
              </li>
              <li>us to correct the information we hold if it is incorrect</li>
              <li>
                us for copies of our privacy policy and this document, in a form
                that suits you (e.g. hardcopy or email)
              </li>
              <li>
                the CRB not to use your information for direct marketing
                assessment purposes, including pre-screening
              </li>
              <li>
                the CRB not to use, or disclose, your information if you have
                reasonable grounds to believe you have, or will be, a fraud
                victim
              </li>
              <li>
                the CRB to provide you with a copy of the information it holds
                about you
              </li>
            </ul>

            <p style={{ marginBottom: "20px" }}>
              You can gain access to the information we hold about you by
              contacting our Privacy Officer by telephone on 02 9418 5513 or
              email at accounthelp@alto.com.au. In some cases an administration
              fee may be charged to cover the cost of providing the information.
            </p>

            <p style={{ marginBottom: "20px" }}>
              Our Privacy Policy is available on our website at
              http://www.alto.com.au/headoffice/alto-headoffice/alto-auto-group-car-dealer-general/
              or we will provide you with a copy if you ask us.
            </p>

            <p style={{ marginBottom: "20px" }}>
              You can contact the CRB by email at
              membership.correction@veda.com.au or visit their website
              www.veda.com.au
            </p>

            <p style={{ marginBottom: "5px" }}>Disclosure and Consent</p>
            <p style={{ marginBottom: "5px" }}>
              By signing below, you agree we may:
            </p>
            <ul style={{ marginBottom: "20px" }}>
              <li>
                Use your personal and credit information:
                <ul>
                  <li>
                    To assess your consumer or commercial credit and/or
                    guarantee application and/or to assess a credit application
                    by a company of which you are a director
                  </li>
                  <li>
                    To manage or better service your, or the company’s, account
                    and any future needs
                  </li>
                  <li>
                    To assist you to manage your debts or the company’s debts or
                    collect any amounts you or the company owes
                  </li>
                  <li>
                    As the law authorises or requires; any third party,
                    including your introducer, information about you, the
                    applicant(s) or guarantor(s) that is reasonably necessary to
                    assess, arrange, provide or administer your credit
                    application or guarantee and any insurance requirements;
                  </li>
                </ul>
              </li>
              <li>
                Provide credit information about you to a guarantor, or
                prospective guarantor;
                <ul>
                  <li>
                    Provide you, or the company of which you are a director,
                    with offers or information of other goods or services we, or
                    any of our associated entities, may be able to provide to
                    you or the company, unless you tell us not to;
                  </li>
                  <li>
                    Disclose your personal and credit information to other
                    organisations that provide us with services, such as
                    contractors, agents, printers, mail houses, lawyers,
                    document custodians, securitisers and computer systems
                    consultants or providers, so they can perform those services
                    for us;
                  </li>
                  <li>
                    Disclose your personal information to any other organisation
                    that may wish to acquire, or has acquired, an interest in
                    our business or any rights under your contract with us, or
                    the contract with us of a company of which you are a
                    director: and
                  </li>
                  <li>
                    Disclose your personal and credit information to a Document
                    Verification Service (DVS) to be subject to an Information
                    Match Request in relation to relevant Official Record Holder
                    information.
                  </li>
                  <li>
                    The Information Match Request, the Information Match Result
                    and other Information Match Data and the use of the DVS may
                    involve the use of third party systems and that information
                    as relevant will be transmitted to New Zealand by the DVS.
                  </li>
                </ul>
              </li>
              <li>
                You also agree that we may, as appropriate:
                <ul>
                  <li>
                    Where you are a consumer credit applicant, notify a CRB you
                    as an individual (not as a director of a company) have
                    applied for credit with us, the amount of the credit
                    required, the contract details, including the amount
                    financed, your status under the contract (borrower /
                    co-borrower / guarantor) and commencement and termination
                    dates;
                  </li>
                  <li>Provide your identification details to the CRB</li>
                  <li>
                    Exchange your credit information with the CRB, any credit
                    provider named in your credit report or in your application
                    and with any introducer assisting you with the application
                  </li>
                  <li>
                    Obtain information about your consumer credit or commercial
                    activities, or commercial credit worthiness, where relevant,
                    from a CRB;
                  </li>
                  <li>
                    Disclose credit information about you to a guarantor, or a
                    prospective guarantor; and
                  </li>
                  <li>
                    When you are a prospective guarantor, obtain a credit report
                    about you to assess whether to accept you as a guarantor.
                  </li>
                </ul>
              </li>
              <li>
                You also agree and consent to, as appropriate:
                <ul>
                  <li>
                    A CRB disclosing consumer credit information to us for
                    considering your consumer or commercial credit or guarantor
                    application, application by a company of which you are a
                    director and/or assessment a credit
                  </li>
                  <li>
                    When you are a prospective guarantor, us using that
                    information to assess your suitability as a guarantor Us
                    disclosing your credit information to a guarantor, or a
                    prospective guarantor
                  </li>
                  <li>
                    Another credit provider, disclosing to us for a particular
                    purpose, information it holds about you Where the applicant,
                    or guarantor, is a company of
                  </li>
                  <li>
                    Where the applicant, or guarantor, is a company of which you
                    are a director, you specifically acknowledge and agree you
                    consent to the use of your information, in addition to the
                    company’s information, in each of the ways listed above.
                  </li>
                </ul>
              </li>
            </ul>

            <p>
              I confirm that I am authorised to provide the personal details
              presented and I consent to my information being checked with the
              document issuer or official record holder for the purpose of
              confirming my identity.
            </p>
          </AccordionDetails>
        </Accordion>

        <FormControlLabel
          style={{ boxShadow: "none", margin: "20px 0", padding: 0 }}
          control={
            <GreenCheckbox
              checked={checked}
              onChange={(event) => setChecked(event.target.checked)}
              style={{ padding: " 0 10px 0 0" }}
            />
          }
          label="Agree to Privacy Agreement."
        />

        <div style={{ margin: "30px 0" }}>
          <div>
            <Button
              // onClick={}
              style={{ padding: "6px 8px 6px 0" }}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={props.showAuthorisation}
              onClick={async () => {
                await signPrivacy({
                  customerId,
                  applicationId,
                });

                await submitLead(attributes);
                history.push(`/privacy-success`);
              }}
            >
              Submit Enquiry
            </Button>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
